import request from '@/utils/request'


// 查询曝光档位列表
export function listExposureGear(query) {
    return request({
        url: '/platform/hot-recharge/list',
        method: 'get',
        params: query
    })
}

// 查询曝光档位分页
export function pageExposureGear(query) {
    return request({
        url: '/platform/hot-recharge/page',
        method: 'get',
        params: query
    })
}

// 查询曝光档位详细
export function getExposureGear(data) {
    return request({
        url: '/platform/hot-recharge/detail',
        method: 'get',
        params: data
    })
}

// 新增曝光档位
export function addExposureGear(data) {
    return request({
        url: '/platform/hot-recharge/add',
        method: 'post',
        data: data
    })
}

// 修改曝光档位
export function updateExposureGear(data) {
    return request({
        url: '/platform/hot-recharge/edit',
        method: 'post',
        data: data
    })
}

// 删除曝光档位
export function delExposureGear(data) {
    return request({
        url: '/platform/hot-recharge/delete',
        method: 'post',
        data: data
    })
}